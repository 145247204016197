<template>
    <b-row>
        <b-col>
            <b-form-input
                type="color"
                v-model="localConfig.color"
            />
        </b-col>
        <b-col>
            <v-select
                v-model="localConfig.opacity"
                :reduce="item => item.value"
                label="name"
                :clearable="false"
                :options="opacityOptions"
            />
        </b-col>
    </b-row>
</template>
<script>

    import { BCol, BFormInput, BRow } from 'bootstrap-vue'
    import vSelect from 'vue-select'

    export default {
        components: {
            BRow,
            BCol,
            BFormInput,
            vSelect
        },
        props: {
            value: {
                type: Object,
                required: true,
                default() {
                    return {
                        color: '#ffffff',
                        opacity: 1.0
                    }
                }
            }
        },
        data() {
            return {
                localConfig: {
                    color: '#ffffff',
                    opacity: 1.0
                },

                opacityOptions: [
                    { value: 0.0, name: '0%' },
                    { value: 0.05, name: '5%' },
                    { value: 0.1, name: '10%' },
                    { value: 0.15, name: '15%' },
                    { value: 0.2, name: '20%' },
                    { value: 0.25, name: '25%' },
                    { value: 0.3, name: '30%' },
                    { value: 0.35, name: '35%' },
                    { value: 0.4, name: '40%' },
                    { value: 0.45, name: '45%' },
                    { value: 0.5, name: '50%' },
                    { value: 0.55, name: '55%' },
                    { value: 0.6, name: '60%' },
                    { value: 0.65, name: '65%' },
                    { value: 0.7, name: '70%' },
                    { value: 0.75, name: '75%' },
                    { value: 0.8, name: '80%' },
                    { value: 0.85, name: '85%' },
                    { value: 0.9, name: '90%' },
                    { value: 0.95, name: '95%' },
                    { value: 1.0, name: '100%' }
                ]
            }
        },
        watch: {
            value: {
                handler() {
                    if (JSON.stringify(this.localConfig) !== JSON.stringify(this.value)) {
                        this.localConfig = {...this.localConfig, ...this.value}
                    }
                },
                deep: true
            },
            localConfig: {
                handler() {
                    if (JSON.stringify(this.localConfig) !== JSON.stringify(this.value)) {
                        this.$emit('input', this.localConfig)
                    }
                },
                deep: true
            }
        },
        created() {
            this.localConfig = {...this.localConfig, ...this.value}
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
