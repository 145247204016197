<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card>
                <b-form-group >
                    <b-form-checkbox v-model="showCategories">Display categories</b-form-checkbox>
                </b-form-group>
                <b-form-group>
                    <b-form-checkbox v-model="showBanner">Display banner</b-form-checkbox>
                </b-form-group>
                <b-form-group>
                    <label>Design:</label>
                    <v-select
                        v-model="selectedDesign"
                        :reduce="item => item.id"
                        label="name"
                        :clearable="false"
                        :options="designs"
                    />
                </b-form-group>

                <div class="mt-5">
                    <GenericBasicItemMaterialConfig ref="design0" :categories="showCategories" v-if="selectedDesign === 0"/>
                </div>

                <b-button variant="success" class="mt-5" @click="addConfig()" v-if="!nameExists">
                    <feather-icon
                        icon="SaveIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>

                <b-button variant="success" class="mt-5" @click="replaceConfig()" v-else>
                    <feather-icon
                        icon="RepeatIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Replace</span>
                </b-button>

                <b-form-input
                    class="mt-1"
                    id="config_name"
                    type="text"
                    placeholder="Name you config... (Christmas colors, Black style, Summer colors)"
                    v-model="saveName"
                />
            </b-card>
        </b-overlay>
        <ConfigsTable ref="configsTable" config-type="base_item" :no-active="true" v-on:dataLoaded="dataLoadedEvent" v-on:loadingStarted="loadingStartedEvent" v-on:loadConfig="loadConfig"/>
    </div>
</template>
<script>

    import { BOverlay, BCard, BButton, BFormGroup, BFormCheckbox, BFormInput } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import GenericBasicItemMaterialConfig from '@/views/GenericItem/Designs/GenericBasicItemMaterialConfig'
    import ConfigsTable from '@/views/components/config/ConfigsTable'

    export default {
        components: {
            ConfigsTable,
            GenericBasicItemMaterialConfig,
            vSelect,
            BOverlay,
            BCard,
            BButton,
            BFormGroup,
            BFormCheckbox,
            BFormInput
        },
        data() {
            return {

                designs: [
                    {
                        id: 0,
                        name: 'Material',
                        ref: 'design0'
                    }
                ],

                selectedDesign: 0,
                saveName: '',
                showCategories: true,
                showBanner: false,
                dataLoaded: false
            }
        },
        methods: {
            dataLoadedEvent() {
                this.dataLoaded = true
            },
            loadingStartedEvent() {
                this.dataLoaded = false
            },
            loadConfig(config) {
                this.selectedDesign = config.config.selected_design
                this.showCategories = config.config.show_categories
                this.showBanner = config.config.show_banner
                this.saveName = config.name
                const thisIns = this
                this.$nextTick(function() {
                    thisIns.$refs[thisIns.designs[thisIns.selectedDesign].ref].setConfig(config.config)
                })
            },
            addConfig() {

                const config = {
                    status: 0,
                    name: this.saveName,
                    config: this.$refs[this.designs[this.selectedDesign].ref].getConfig()
                }
                config.config.selected_design = this.selectedDesign
                config.config.show_categories = this.showCategories
                config.config.show_banner = this.showBanner

                if (!this.$refs[this.designs[this.selectedDesign].ref].valid(this.showCategories)) {
                    this.$printError('Configuration is not valid. Check if all settings are correctly set')
                } else {
                    const thisIns = this
                    const loadPromise = this.$http.post('/api/management/v1/config/design/base_item', config)
                    loadPromise.then(function() {
                        thisIns.$printSuccess('Config added')
                    }).catch(function(error) {
                        thisIns.$printError(error.response.data)
                    }).finally(function() {
                        thisIns.$refs.configsTable.loadData()
                    })
                }
            },
            replaceConfig(addStatus) {

                const config = {
                    status: addStatus,
                    name: this.saveName,
                    config: this.$refs[this.designs[this.selectedDesign].ref].getConfig()
                }
                config.config.selected_design = this.selectedDesign
                config.config.show_categories = this.showCategories
                config.config.show_banner = this.showBanner

                if (!this.$refs[this.designs[this.selectedDesign].ref].valid(this.showCategories)) {
                    this.$printError('Configuration is not valid. Check if all settings are correctly set')
                } else {
                    const thisIns = this
                    const loadPromise = this.$http.put('/api/management/v1/config/design/base_item', config)
                    loadPromise.then(function() {
                        thisIns.$printSuccess('Config replaced')
                    }).catch(function(error) {
                        thisIns.$printError(error.response.data)
                    }).finally(function() {
                        thisIns.$refs.configsTable.loadDataNoReplace()
                    })
                }
            }
        },
        computed: {
            nameExists() {
                const localName = this.saveName
                if (!this.$refs.configsTable) return false
                return this.$refs.configsTable.getConfigs().some((item) => item.name === localName)
            }
        }
    }

</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
