<template>
    <b-overlay :show="!dataLoaded">
        <b-card>
            <basic-table :columns="columns" :data="configs" v-slot="props">
                <span v-if="props.column.displayType === 1 && !noActive" class="d-flex justify-content-center">
                    <b-badge v-if="props.row.status === 1" variant="light-success">
                        Yes
                    </b-badge>
                    <b-badge v-else variant="light-danger">
                        No
                    </b-badge>
                </span>
                <span v-else-if="props.column.displayType === 2">
                    {{ moment(props.formattedRow[props.column.field]).format('YYYY/MM/DD HH:mm') }}
                </span>
                <div v-else-if="props.column.displayType === 3">

                    <b-button variant="primary" @click="$emit('loadConfig', JSON.parse(JSON.stringify(props.row)))" class="mr-1">
                        <feather-icon
                            icon="EditIcon"
                            class="mr-50"
                        />
                        <span class="align-middle">Load to editor</span>
                    </b-button>

                    <b-button v-if="props.row.status !== 1 && !noActive" variant="warning" @click="setActive(props.row.id)" class="mr-1">
                        <feather-icon
                            icon="CheckCircleIcon"
                            class="mr-50"
                        />
                        <span class="align-middle">Set active</span>
                    </b-button>

                    <b-button v-if="props.row.status !== 1" variant="danger" @click="removeConfig(props.row.id)" class="mr-1">
                        <feather-icon
                            icon="XIcon"
                            class="mr-50"
                        />
                        <span class="align-middle">Remove config</span>
                    </b-button>
                </div>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </basic-table>
        </b-card>
    </b-overlay>
</template>
<script>

    import BasicTable from '@/views/components/BasicTable'
    import { BBadge, BCard, BOverlay, BButton } from 'bootstrap-vue'

    export default {
        components: {
            BasicTable,
            BCard,
            BBadge,
            BOverlay,
            BButton
        },
        props: {
            configType: {
                type: String,
                required: true
            },
            noActive: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {

                configs: [],
                dataLoaded: false,

                columns: [
                    {
                        label: 'Name',
                        displayType: 0,
                        field: 'name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search Name'
                        }
                    },
                    ...(!this.noActive ? [
                        {
                            label: 'Active',
                            displayType: 1,
                            field(rowObj) {
                                return (rowObj.status === 1) ? 'Yes' : 'No'
                            },
                            filterOptions: {
                                enabled: true,
                                placeholder: 'Yes / No'
                            }
                        }
                    ] : []),
                    {
                        label: 'Added',
                        displayType: 2,
                        field: 'added_time',
                        filterOptions: {
                            enabled: false,
                            placeholder: 'Search data added'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 3,
                        field: 'actions',
                        filterOptions: {
                            enabled: false
                        }
                    }
                ]
            }
        },
        methods: {
            getConfigs() {
                return this.configs
            },
            loadData() {
                this.dataLoaded = false
                this.$emit('loadingStarted')
                const thisIns = this
                const loadPromise = this.$http.get(`/api/management/v1/config/design/${  this.configType}`)
                loadPromise.then(function(response) {
                    thisIns.configs = response.data
                    if (thisIns.configs.length > 0) {
                        if (thisIns.configs.filter(config => config.status === 1).length === 1) {
                            thisIns.$emit('loadConfig', JSON.parse(JSON.stringify(thisIns.configs.filter(config => config.status === 1)[0])))
                        } else {
                            thisIns.$emit('loadConfig', JSON.parse(JSON.stringify(thisIns.configs[0])))
                        }
                    }
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.dataLoaded = true
                    thisIns.$emit('dataLoaded')
                })
            },
            loadDataNoReplace() {
                this.dataLoaded = false
                this.$emit('loadingStarted')
                const thisIns = this
                const loadPromise = this.$http.get(`/api/management/v1/config/design/${  this.configType}`)
                loadPromise.then(function(response) {
                    thisIns.configs = response.data
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.dataLoaded = true
                    thisIns.$emit('dataLoaded')
                })
            },
            setActive(id) {
                this.dataLoaded = false
                this.$emit('loadingStarted')
                const thisIns = this
                const setActivePromise = this.$http.put(`/api/management/v1/config/design/${  this.configType}/${  id}/activate`)
                setActivePromise.then(function() {
                    thisIns.$printSuccess('Changed active config')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            removeConfig(id) {
                this.dataLoaded = false
                this.$emit('loadingStarted')
                const thisIns = this
                const removePromise = this.$http.delete(`/api/management/v1/config/design/${  this.configType}/${  id}`)
                removePromise.then(function() {
                    thisIns.$printSuccess('Removed config')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.loadData()
                })
            }
        },
        created() {
            this.loadData()
        }
    }

</script>
