<template>
    <div style="display: inline">
        <fa :id="img+cid" class="ml-1" icon="question-circle"/>
        <b-tooltip :target="img+cid" triggers="hover focus" custom-class="expanded-tooltip" :delay="{ show: 600, hide: 700 }">
            <img :src="img" :alt="img" class="innerImg rounded-md"/>
        </b-tooltip>
    </div>
</template>
<script>
    import {BTooltip} from 'bootstrap-vue'

    export default {
        components: {BTooltip},
        props: ['img', 'cid'],
        mounted() {
            (new Image()).src = this.img
        }
    }

</script>

<style>

.innerImg {
  max-height:500px; max-width:500px; height:auto; width:auto;
}

</style>
