<template>
    <div>
        <label for="main_background_type">Background type:</label>
        <v-select
            id="main_background_type"
            v-model="localConfig.type"
            :reduce="item => item.id"
            label="name"
            :clearable="false"
            :options="mainBackgroundTypes"
        />

        <div v-if="localConfig.type === 2" class="mt-1">
            <b-form-group>
                <label for="imageOpacity">Opacity:</label>
                <v-select
                    id="imageOpacity"
                    v-model="localConfig.opacity"
                    :reduce="item => item.value"
                    label="name"
                    :clearable="false"
                    :options="opacityOptions"
                />
            </b-form-group>
            <b-form-group>
                <label>Background image:</label>
                <ImageSelector ref="ImageSelector" v-model="localConfig.image"/>
            </b-form-group>
        </div>
        <div v-if="localConfig.type === 0" class="mt-1">
            <b-form-group>
                <label for="main_background_color">Background color:</label>
                <b-form-input
                    id="main_background_color"
                    type="color"
                    v-model="localConfig.color"
                />
            </b-form-group>
            <b-form-group>
                <label for="colorOpacity">Opacity:</label>
                <v-select
                    id="colorOpacity"
                    v-model="localConfig.opacity"
                    :reduce="item => item.value"
                    label="name"
                    :clearable="false"
                    :options="opacityOptions"
                />
            </b-form-group>
        </div>
        <div v-if="localConfig.type === 1" class="mt-1">
            <b-form-group>
                <label for="main_background_gradient_type">Gradient type:</label>
                <v-select
                    id="main_background_gradient_type"
                    v-model="localConfig.gradient_type"
                    :reduce="item => item.id"
                    label="name"
                    :clearable="false"
                    :options="gradientTypes"
                />
            </b-form-group>
            <b-form-group v-if="localConfig.gradient_type === 0">
                <label for="main_background_gradient_direction">Gradient direction:</label>
                <v-select
                    id="main_background_gradient_direction"
                    v-model="localConfig.gradient_direction"
                    :reduce="item => item.id"
                    label="name"
                    :clearable="false"
                    :options="gradientDirections"
                />
            </b-form-group>
            <b-form-group>
                <label>Gradient colors:</label>
                <b-form-group
                    :key="index"
                    v-for="(color, index) in localConfig.colors"
                >
                    <b-row>
                        <b-col>
                            <b-form-input
                                type="color"
                                v-model="localConfig.colors[index].color"
                            />
                        </b-col>
                        <b-col>
                            <v-select
                                v-model="localConfig.colors[index].opacity"
                                :reduce="item => item.value"
                                label="name"
                                :clearable="false"
                                :options="opacityOptions"
                            />
                        </b-col>
                        <b-col>
                            <b-button variant="danger" @click="localConfig.colors.splice(index, 1)">
                                <feather-icon icon="XIcon"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form-group>


            </b-form-group>
            <b-form-group>
                <b-button variant="primary" @click="localConfig.colors.push({color: '#ffffff', opacity: 1.0})">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add color</span>
                </b-button>
            </b-form-group>
        </div>
    </div>
</template>
<script>

    import { BFormGroup, BFormInput, BButton, BRow, BCol } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import ImageSelector from '@/views/components/ImageSelector'

    export default {
        props: {
            value: {
                type: Object,
                required: true
            },
            backgroundOptions: {
                type: Array,
                required: false,
                default: () => [0, 1, 2]
            }
        },
        components: {
            ImageSelector,
            BFormGroup,
            BFormInput,
            BButton,
            BRow,
            BCol,
            vSelect
        },
        data() {
            return {
                localConfig: {
                    type: 0,
                    image: '',
                    color: '#ffffff',
                    gradient_type: 0,
                    gradient_direction: 0,
                    colors: [],
                    opacity: 1.0
                },

                mainBackgroundTypes: [],

                gradientTypes: [
                    {
                        id: 0,
                        name: 'Linear'
                    },
                    {
                        id: 1,
                        name: 'Radial'
                    },
                    {
                        id: 2,
                        name: 'Sweep'
                    }
                ],

                gradientDirections: [
                    {
                        id: 0,
                        name: 'Bottom-left to top-right'
                    },
                    {
                        id: 1,
                        name: 'Bottom to top'
                    },
                    {
                        id: 2,
                        name: 'Bottom-right to top-left'
                    },
                    {
                        id: 3,
                        name: 'Left to right'
                    },
                    {
                        id: 4,
                        name: 'Top-left to bottom-right'
                    },
                    {
                        id: 5,
                        name: 'Top to bottom'
                    },
                    {
                        id: 6,
                        name: 'Top-right to bottom-left'
                    },
                    {
                        id: 7,
                        name: 'Right to left'
                    }
                ],

                opacityOptions: [
                    { value: 0.0, name: '0%' },
                    { value: 0.05, name: '5%' },
                    { value: 0.1, name: '10%' },
                    { value: 0.15, name: '15%' },
                    { value: 0.2, name: '20%' },
                    { value: 0.25, name: '25%' },
                    { value: 0.3, name: '30%' },
                    { value: 0.35, name: '35%' },
                    { value: 0.4, name: '40%' },
                    { value: 0.45, name: '45%' },
                    { value: 0.5, name: '50%' },
                    { value: 0.55, name: '55%' },
                    { value: 0.6, name: '60%' },
                    { value: 0.65, name: '65%' },
                    { value: 0.7, name: '70%' },
                    { value: 0.75, name: '75%' },
                    { value: 0.8, name: '80%' },
                    { value: 0.85, name: '85%' },
                    { value: 0.9, name: '90%' },
                    { value: 0.95, name: '95%' },
                    { value: 1.0, name: '100%' }
                ]
            }
        },
        watch: {
            value: {
                handler() {
                    if (JSON.stringify(this.localConfig) !== JSON.stringify(this.value)) {
                        this.localConfig = {...this.localConfig, ...this.value}
                    }
                },
                deep: true
            },
            localConfig: {
                handler() {
                    if (JSON.stringify(this.localConfig) !== JSON.stringify(this.value)) {
                        this.$emit('input', this.localConfig)
                    }
                },
                deep: true
            }
        },
        created() {

            const mainBackgroundTypes = [
                {
                    id: 0,
                    name: 'Solid color'
                },
                {
                    id: 1,
                    name: 'Gradient'
                },
                {
                    id: 2,
                    name: 'Image'
                }
            ]

            for (let x = 0; x < this.backgroundOptions.length; x++) {
                this.mainBackgroundTypes.push(mainBackgroundTypes[this.backgroundOptions[x]])
            }

            this.localConfig = {...this.localConfig, ...this.value}
        },
        methods: {
            valid() {
                if (this.localConfig.type === 1) {
                    return (this.localConfig.colors.length > 1)
                } else if (this.localConfig.type === 2) {
                    return this.$refs.ImageSelector.valid()
                }
                return true
            }
        }
    }

</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
